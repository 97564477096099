<template>
    <div class="h-100 p-relative">
        <b-sidebar
                id="sidebar-add-new-event"
                sidebar-class="sidebar-lg"
                :visible="isEventHandlerSidebarActive"
                bg-variant="white"
                shadow
                backdrop
                no-header
                right
                @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
        >
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h5 class="mb-0">
                        Live Audition Details
                    </h5>
                    <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                    />
                </div>
                <div class="px-2 pt-1 pb-2">
                    <b-media vertical-align="top" class="pb-1 d-flex align-items-center">
                        <template #aside>
                            <b-avatar size="80px"
                                      v-if="eventLocal.extendedProps.userAvatar"
                                      :src="eventLocal.extendedProps.userAvatar.url"/>
                            <b-avatar size="80px"
                                      v-else
                                      :src="defaultLogo()"/>
                        </template>
                        <h5>
                            {{eventLocal.title}}
                        </h5>
                    </b-media>
                    <div>
                        <b class="cal-title-size mb-0">Role:</b>
                        <p>{{eventLocal.extendedProps.roleName}}</p>
                    </div>
                    <div>
                        <b class="cal-title-size mb-0">Audition Date:</b>
                        <p>{{niceDate(eventLocal.start)}}</p>
                    </div>
                    <div>
                        <p><a :href="eventLocal.extendedProps.eventUrl"><b class="cal-title-size mb-0">Open Zoom Link</b></a></p>
                    </div>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import {
        BSidebar, BAvatar, BMedia
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import moment from "moment";
    import {toRefs} from '@vue/composition-api'
    import useCalendarEventHandler from './useCalendarEventHandler'

    export default {
        components: {
            BSidebar,
            BAvatar,
            BMedia
        },
        directives: {
            Ripple,
        },
        model: {
            prop: 'isEventHandlerSidebarActive',
            event: 'update:is-event-handler-sidebar-active',
        },
        props: {
            isEventHandlerSidebarActive: {
                type: Boolean,
                required: true,
            },
            event: {
                type: Object,
                required: true,
            },
        },
        mounted(){
          console.log(this.event);
        },
        setup(props) {
            const {
                eventLocal,
            } = useCalendarEventHandler(toRefs(props))
            return {
                eventLocal,
            }
        },
        methods: {
            niceDate(parseDate) {
                return moment(parseDate).format("DD-MM-YYYY HH:mm");
            },
        },
//        watch: {
//            event: {
//                handler(newVal){
//                    console.log("Event: ", newVal);
//                }
//            }
//        }
    }
</script>

<style scoped>
    .cal-title-size {
        font-size: 12px;
    }
    .qf-profile-avatar {
        width: 100px;
        height: 100px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 50%;
    }
</style>
