import axios from '@axios'
import Parse from 'parse';

export default {
  namespaced: true,
  state: {
    calendarOptions: [],
    selectedCalendars: [],
  },
  getters: {},
  mutations: {
    SET_CALENDAR_OPTIONS(state, val) {
      state.calendarOptions = val
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchRoles(ctx, { productionId }) {
      return new Promise((resolve, reject) => {
        const ActingRole = Parse.Object.extend('ActingRole');
        const query = new Parse.Query(ActingRole);

        const rolePointer = { "__type": "Pointer", "className": "Production", "objectId": productionId };

        query
          .equalTo("production", rolePointer)
          // .addAscending('roleType')
          .addAscending('priority')
          .find()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        const roleQuery = new Parse.Query('ActingRole');
        roleQuery.containedIn('objectId', calendars);

        const query = new Parse.Query('LiveAudition');
        
        query
          .matchesQuery('relatedRole', roleQuery)
          .includeAll()
          .findAll()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
